import React from 'react';
import { FormsPaymentTable } from '../../Components/FormsPayments/FormsPaymentTable';

export const FormsPaymentScreen = () => {
  return (
    <div className="forms-screen">
      <FormsPaymentTable />
    </div>
  );
};
