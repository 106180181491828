import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, message, Select, Row, Col, Button } from 'antd';
import logo from '../../Assets/img/logo.jpg';
import IMG1 from '../../Assets/img/IMG1.jpg';
import IMG2 from '../../Assets/img/IMG2.jpg';
import IMG3 from '../../Assets/img/IMG3.jpg';
import IMG4 from '../../Assets/img/IMG4.jpg';
import { useFetchClientsWithApiKey } from '../../Hooks';
import { useNavigation } from '../../Hooks';
const layout = {
  labelCol: { span: 3 }, // Tamaño del label en términos de columnas
  wrapperCol: { span: 21 }, // Tamaño del wrapper en términos de columnas
};
const GenerateInvoiceForm = () => {
  const [loading, setLoading] = useState(false);
  const [, nav] = useNavigation();
  const [form] = Form.useForm();

  const onFinish = async values => {
    setLoading(true);
    const rfc = values.rfc.toUpperCase();
    const branch_id = values.branch_id;
    const query = `rfc=${rfc}&branch_id=${branch_id}`;
    try {
      const response = await useFetchClientsWithApiKey(query);
      if (response.data.data.length === 0) {
        message.info(
          'No se encontro al cliente en dicha sucursal, chequee sus datos o registrese'
        );
        nav('/newClient');
      } else {
        nav(`/tickets/${rfc}&${branch_id}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.error('Error de autenticación');
      } else {
        console.error('Error fetching data:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        onFinish(formValues);
      })
      .catch(error => {
        console.log(error);
        message.error('Un error ha ocurrido');
      });
  };

  return (
    <Row
      justify="center"
      style={{ height: '100vh', background: '#f7f7f7' }}
      gutter={[16, 16]}
    >
      <Col span={8} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row gutter={[24, 24]} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <img
              src={IMG1}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col>
            <img
              src={IMG2}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={8}
        style={{ textAlign: 'center' }}
        xs={24}
        sm={24}
        md={24}
        lg={8}
        xl={8}
      >
        <Row>
          <Col span={24}>
            <img
              src={logo}
              alt="Logo"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </Col>
          <Col span={24}>
            <h2>Genera tu factura de forma automática.</h2>
          </Col>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              name="generateInvoice"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              initialValues={{
                ['branch_id']: '23c2033f-0fbb-4db9-bad6-862f1ad9a11f',
              }}
              onFinish={onFinish}
              {...layout}
            >
              <Form.Item
                style={{
                  borderStyle: 'none',
                  boxShadow: 'none',
                  color: '#FFF',
                  maxWidth: '100%',
                }}
                label="Sucursal"
                name="branch_id"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Select
                  style={{
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: '#BAEE55',
                  }}
                >
                  <Select.Option
                    key={'23c2033f-0fbb-4db9-bad6-862f1ad9a11f'}
                    value={'23c2033f-0fbb-4db9-bad6-862f1ad9a11f'}
                  >
                    {'B HOUSE'}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                style={{ marginTop: 20 }}
                name="rfc"
                rules={[
                  { required: true, message: 'Por favor ingrese su RFC' },
                ]}
                label={
                  <label style={{ color: '#000000', align: 'center' }}>
                    RFC:
                  </label>
                }
              >
                <Input.TextArea
                  id="rfc-input"
                  placeholder="Ingrese su RFC"
                  disabled={loading}
                  autoSize="true"
                  style={{
                    width: '100%',
                    borderBottom: '1px solid #BAEE55',
                    color: 'black',
                  }}
                  autoComplete="off"
                  size="large"
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              block
              onClick={handleButtonClick}
              style={{
                cursor: 'pointer',
              }}
            >
              {loading ? (
                <>
                  <LoadingOutlined style={{ color: 'black' }} />
                  <span> Ingresando...</span>
                </>
              ) : (
                <span>Ingresar</span>
              )}
            </Button>
          </Col>

          <Col span={24}>
            <Button
              type="dashed"
              block
              style={{
                cursor: 'pointer',
              }}
              onClick={() => nav('/newClient')}
            >
              ¿No estás registrado? Regístrate aquí
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={8} xs={0} sm={0} md={0} lg={8} xl={8}>
        <Row gutter={[24, 24]}>
          <Col>
            <img
              src={IMG3}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col>
            <img
              src={IMG4}
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GenerateInvoiceForm;
