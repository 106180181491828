import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Upload, Form, Row, message, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { TeamOutlined } from '@ant-design/icons';
import EXCELJS from 'exceljs';
import fileSaver from 'file-saver';

const { Dragger } = Upload;

const MultipleModal = ({ submitDataContrats }) => {
  const [modal, setModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();

  const columns_excel_contracts = [
    'plazo credito',
    'seguros aseguradora',
    'seguros clausula',
    'seguros nombre',
    'tasa anual interes',
    'tasa interes ordinaria',
    'tipo de credito',
    'vigencia contrato',
    'comision por disposicion anticipada',
    'cta',
    'cuenta acreditado',
    'cuenta dvm banco',
    'cuenta dvm clabe',
    'garantia',
    'destino credito',
    'dias ant fecha', //Hay que cambiar el nombre
    'fecha corte',
    'fecha inicio del periodo de intereses',
    'fecha limite',
    'fecha pago',
    'monto del credito',
    'monto minino a cada disposicion',
    'monto total',
    'advertencia',
    'aclaracion reclamaciones correo',
    'aclaracion reclamaciones domicilio',
    'aclaracion reclamaciones pagina int',
    'aclaracion reclamaciones telefono',
    'comisiones relevantes anualidad',
    'comisiones relevantes apertura',
    'comisiones relevantes cobranza',
    'comisiones relevantes dispo anticipada',
    'comisiones relevantes pago tardio',
    'comisiones relevantes prepago',
    'comisiones relevantes reclamacion improcedente',
    'comisiones relevantes reposicion tarjeta',
    'datos inscripcion folio mercantil numero',
    'datos inscripcion registro publico',
    'escritura publica fecha',
    'escritura publica inst publico numero',
    'escritura publica localidad',
    'escritura publica nombre',
    'escritura publica numero',
    'instrumento publico fecha',
    'instrumento publico folio mercantil numero',
    'instrumento publico inst publico numero',
    'instrumento publico localidad',
    'instrumento publico nombre',
    'instrumento publico numero',
    'instrumento publico registro publico',
    'objeto social principal',
    'obligado solidario apoderado',
    'obligado solidario correo',
    'obligado solidario datos inscripcion folio mercantil numero',
    'obligado solidario datos inscripcion registro publico',
    'obligado solidario denominacion social',
    'obligado solidario domicilio',
    'obligado solidario escritura publica fecha',
    'obligado solidario escritura publica inst publico numero',
    'obligado solidario escritura publica localidad',
    'obligado solidario escritura publica nombre',
    'obligado solidario escritura publica numero',
    'obligado solidario inst publico fecha',
    'obligado solidario inst publico folio mercantilnum',
    'obligado solidario inst publico localidad',
    'obligado solidario inst publico nombre',
    'obligado solidario inst publico numero',
    'obligado solidario inst publico registro publico',
    'obligado solidario numero',
    'obligado solidario objeto social principal',
    'obligado solidario rfc',
    'obligado solidario telefono',
  ];

  const columns_db_contracts = [
    'plazo_credito',
    'seguros_aseguradora',
    'seguros_clausula',
    'seguros_nombre',
    'tasa_anual_interes',
    'tasa_interes_ord',
    'tipo_credito',
    'vigencia_contrato',
    'comision_disp_ant',
    'cta',
    'cuenta_acreditado',
    'cuenta_dvm_banco',
    'cuenta_dvm_clabe',
    'garantia',
    'destino_credito',
    'dias_ant_fecha',
    'fecha_corte',
    'fecha_ini_intereses',
    'fecha_limite',
    'fecha_pago',
    'monto_credito',
    'monto_min_disposicion',
    'monto_total',
    'advertencia',
    'aclaracion_reclamaciones_correo',
    'aclaracion_reclamaciones_domicilio',
    'aclaracion_reclamaciones_pagina_int',
    'aclaracion_reclamaciones_telefono',
    'comisiones_relevantes_anualidad',
    'comisiones_relevantes_apertura',
    'comisiones_relevantes_cobranza',
    'comisiones_relevantes_dispo_anticipada',
    'comisiones_relevantes_pago_tardio',
    'comisiones_relevantes_prepago',
    'comisiones_relevantes_reclamacion_improcedente',
    'comisiones_relevantes_reposicion_tarjeta',
    'datos_inscripcion_folio_mercantil_num',
    'datos_inscripcion_registro_publico',
    'escritura_publica_fecha',
    'escritura_publica_int_publico_numero',
    'escritura_publica_localidad',
    'escritura_publica_name',
    'escritura_publica_numero',
    'int_publico_fecha',
    'int_publico_folio_mercantil_num',
    'int_publico_int_publico_numero',
    'int_publico_localidad',
    'int_publico_name',
    'int_publico_numero',
    'int_publico_registro_publico',
    'objeto_social_principal',
    'obligado_solidario_apoderado',
    'obligado_solidario_correo',
    'obligado_solidario_datos_inscripcion_folio_mercantil_num',
    'obligado_solidario_datos_inscripcion_registro_publico',
    'obligado_solidario_denominacion_social',
    'obligado_solidario_domicilio',
    'obligado_solidario_escritura_publica_fecha',
    'obligado_solidario_escritura_publica_int_publico_numero',
    'obligado_solidario_escritura_publica_localidad',
    'obligado_solidario_escritura_publica_name',
    'obligado_solidario_escritura_publica_numero',
    'obligado_solidario_int_publico_fecha',
    'obligado_solidario_int_publico_folio_mercantil_num',
    'obligado_solidario_int_publico_localidad',
    'obligado_solidario_int_publico_name',
    'obligado_solidario_int_publico_numero',
    'obligado_solidario_int_publico_registro_publico',
    'obligado_solidario_numero',
    'obligado_solidario_objeto_social_principal',
    'obligado_solidario_rfc',
    'obligado_solidario_telefono',
  ];

  const uploader_props = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76', // ?? Asi esta en antd
    beforeUpload(file) {
      const isExcel =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('Solo se permiten archivos de Excel');
      }
      return isExcel || Upload.LIST_IGNORE;
    },
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.slice(-1);
      setFileList(newFileList);
    },
  };

  const downloadLayoutExcel = (columns, filename) => {
    const workbook = new EXCELJS.Workbook();
    const worksheet = workbook.addWorksheet('Clientes');
    worksheet.addRow(columns);
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      fileSaver(blob, `${filename}.xlsx`);
    });
  };

  const handleExcelData = async values => {
    const workbook = new EXCELJS.Workbook();
    await workbook.xlsx.load(fileList[0].originFileObj);
    const worksheet = workbook.getWorksheet(1);
    const row1 = worksheet.getRow(1);

    let columns = [];
    let columns2 = [];
    if (values) {
      columns = columns_excel_contracts;
      columns2 = columns_db_contracts;
    } else {
      message.error('Tipo de persona no válido');
      return;
    }

    for (let i = 0; i < columns.length; i++) {
      if (row1.getCell(i + 1).value.toLowerCase() !== columns[i]) {
        message.error('El archivo no tiene las columnas correctas');
        return;
      }
    }

    const employees = [];
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        return;
      }
      const employee = {};
      row.eachCell((cell, colNumber) => {
        if (columns2[colNumber - 1] === 'email') {
          employee[columns2[colNumber - 1]] = '' + cell.text;
        } else {
          employee[columns2[colNumber - 1]] = '' + cell.value;
        }
      });
      employees.push(employee);
    });
    submitDataContrats.setFieldsValue({ ...employees[0] });
    setFileList([]);
    setModal(false);
  };

  return (
    <>
      <Button onClick={() => setModal(true)} type="primary">
        Importar Datos desde Excel
        <TeamOutlined />
      </Button>

      <Modal
        title="Subir Excel"
        visible={modal}
        onOk={() => form.submit()}
        onCancel={() => {
          setFileList([]);
          setModal(false);
        }}
      >
        <Form form={form} onFinish={handleExcelData}>
          <Form.Item name="file">
            <Dragger {...uploader_props} fileList={fileList}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Haga click o arrastre un archivo de excel para subirlo
              </p>
              <p className="ant-upload-hint">
                Suba un archivo de excel con los datos para generar el contrato
              </p>
            </Dragger>
          </Form.Item>
          <Row>
            <Button
              color="secodary"
              onClick={() =>
                downloadLayoutExcel(
                  columns_excel_contracts,
                  'Plantilla Contrato Compañia'
                )
              }
            >
              Plantilla Contrato
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

MultipleModal.propTypes = {
  submitDataContrats: PropTypes.func.isRequired,
};

export default MultipleModal;
