import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Tabs, Divider, DatePicker } from 'antd';
import MultipleModal from '../Branches/MultipleModal';
const { TabPane } = Tabs;
const dividerStyle = {
  fontSize: '12px', // Tamaño de fuente pequeño
  opacity: '0.5', // Color opaco
};
export const ContractsForm = ({ formRef, onSubmit }) => {
  return (
    <Form
      form={formRef}
      layout="vertical"
      name="ContractsForm"
      onFinish={onSubmit}
    >
      <MultipleModal submitDataContrats={formRef} />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Parte I" key="1">
          <Row gutter={[12, 12]}>
            <Divider orientation="center">I.Caratula</Divider>
            {/*///////////admin///////////////// */}
            <Col span={8}>
              <Form.Item
                label="Tasa de Interés Anual y Moratoria"
                name="tasa_anual_interes"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* /////////no es del admin//// */}
            <Col span={8}>
              <Form.Item
                label="Monto total a Pagar o Mínimo a Pagar"
                name="monto_total"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* /////////////end///////////////// */}
            {/* //////////////////////se guardar ///////////////////////*/}
            <Col span={8}>
              <Form.Item
                label="Monto o Línea de Crédito"
                name="monto_credito"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* //////////////////////////////////////////////////////////////// */}
            {/* ///////temporal////// */}
            <Col span={8}>
              <Form.Item
                label="Destino del credito"
                name="destino_credito"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* //////end/////  */}
            <Col span={8}>
              <Form.Item
                label="Plazo del Crédito"
                name="plazo_credito"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Fecha Límite de Pago"
                name="fecha_limite"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Fecha de Corte"
                name="fecha_corte"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            {/* ////////temporal////////// */}
            <Col span={8}>
              <Form.Item
                label="Tipo de Credito"
                name="tipo_credito"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="CTA"
                name="cta"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Advertencias"
                name="advertencia"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* //////////////////////////////////////////////////  */}
            <Divider orientation="center" style={dividerStyle}>
              Comisiones Relevantes
            </Divider>
            <Col span={9} offset={3}>
              <Form.Item label="Apertura" name="comisiones_relevantes_apertura">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="Anualidad"
                name="comisiones_relevantes_anualidad"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9} offset={3}>
              <Form.Item label="Prepago" name="comisiones_relevantes_prepago">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="Pago tardío (mora)"
                name="comisiones_relevantes_pago_tardio"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9} offset={3}>
              <Form.Item
                label="Reposición de tarjeta"
                name="comisiones_relevantes_reposicion_tarjeta"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="Reclamación Improcedente"
                name="comisiones_relevantes_reclamacion_improcedente"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9} offset={3}>
              <Form.Item label="Cobranza" name="comisiones_relevantes_cobranza">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                label="Disposición Anticipada"
                name="comisiones_relevantes_dispo_anticipada"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Divider orientation="center" style={dividerStyle}>
              Seguros
            </Divider>
            <Col span={8}>
              <Form.Item
                label="Seguro (Opcional u obligatorio)"
                name="seguros_nombre"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Aseguradora" name="seguros_aseguradora">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Clausula" name="seguros_clausula">
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* ////////////admin end////////////// */}
            <Divider orientation="center" style={dividerStyle}>
              Aclaraciones y Reclamaciones
            </Divider>
            <Col span={12}>
              <Form.Item
                label="Domicilio"
                name="aclaracion_reclamaciones_domicilio"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Telefono"
                name="aclaracion_reclamaciones_telefono"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Pagina web"
                name="aclaracion_reclamaciones_pagina_int"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="aclaracion_reclamaciones_correo"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="center">II.Condiciones de Credito</Divider>
          <Row gutter={[12, 12]}>
            {/* ////////////////////admin start////////////// */}
            {/* /////////temporal//////////////  */}
            <Col span={8}>
              <Form.Item
                label="Comisión por Disposición Anticipada"
                name="comision_disp_ant"
              >
                <Input
                  type="text"
                  rules={[{ required: true, message: 'Campo requerido' }]}
                />
              </Form.Item>
            </Col>
            {/* ///////end/////////////// */}
            <Col span={8}>
              <Form.Item
                label="Fecha de Inicio del Periodo de Interés"
                name="fecha_ini_intereses"
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Monto Mínimo de Cada Dispersión"
                name="monto_min_disposicion"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Tasa de Interés Ordinaria"
                name="tasa_interes_ord"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Días de Anticipación a (CFD)"
                name="dias_ant_fecha"
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Fecha de Pago" name="fecha_pago">
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Vigencia del Contrato" name="vigencia_contrato">
                <Input
                  type="text"
                  rules={[{ required: true, message: 'Campo requerido' }]}
                />
              </Form.Item>
            </Col>
            {/* ////////temporal//////////// */}
            <Divider orientation="center" style={dividerStyle}>
              Cuenta Davmon
            </Divider>
            <Col span={6}>
              <Form.Item label="BANCO" name="cuenta_dvm_banco">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="CLABE" name="cuenta_dvm_clabe">
                <Input type="text" />
              </Form.Item>
            </Col>
            {/* /////////end//////////// */}
            {/* /////////////admin end //////////////  */}
            <Col span={6}>
              <Form.Item
                label="Cuenta acreditado"
                name="cuenta_acreditado"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Garantía." name="garantia">
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Parte II" key="2">
          <Divider orientation="center">III.Datos de Acreditado</Divider>
          <Row gutter={[12, 12]}>
            {/* label="instumento publico de constitución*/}
            <Divider orientation="center" style={dividerStyle}>
              instumento publico de constitución
            </Divider>
            {/* en caso de ser persona moral */}
            <Col span={7} offset={1}>
              <Form.Item
                label="Instrumento público número"
                name="int_publico_int_publico_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Fecha"
                name="int_publico_fecha"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Nombre"
                name="int_publico_name"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label="Numero"
                name="int_publico_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Localidad"
                name="int_publico_localidad"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Folio mercantil numero"
                name="int_publico_folio_mercantil_num"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7} offset={8}>
              <Form.Item
                label="Registro publico"
                name="int_publico_registro_publico"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Divider orientation="center" style={dividerStyle}>
              Datos de escritura publica
            </Divider>
            <Col span={8}>
              <Form.Item
                label="Instrumento público
                número"
                name="escritura_publica_int_publico_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Fecha"
                name="escritura_publica_fecha"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Nombre"
                name="escritura_publica_name"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Numero"
                name="escritura_publica_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Localidad"
                name="escritura_publica_localidad"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Divider orientation="center" style={dividerStyle}>
              Datos de inscripción
            </Divider>
            <Col span={8}>
              <Form.Item
                label="Folio mercantil numero"
                name="datos_inscripcion_folio_mercantil_num"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Registro publico"
                name="datos_inscripcion_registro_publico"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="objeto social principal"
                name="objeto_social_principal"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          {/* <Col span={12}>
              <Form.Item
                label="Nombre del apoderado o representante legal"
                name="address"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Datos de la escritura publica"
                name="address"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Datos de Inscripción"
                name="address"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tiene por objeto social principal / Se dedica a:"
                name="address"
              >
                <Input type="text" />
              </Form.Item>
            </Col> */}
        </TabPane>
        <TabPane tab="Parte III" key="3">
          <Divider orientation="center">
            III.Datos de OBLIGADO SOLIDARIO:
          </Divider>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Denominación social"
                name="obligado_solidario_denominacion_social"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Nombre del apoderado"
                name="obligado_solidario_apoderado"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            {/* label="instumento publico de constitución*/}
            <Divider orientation="center" style={dividerStyle}>
              instumento publico de constitución
            </Divider>
            {/* en caso de ser persona moral */}
            <Col span={7} offset={1}>
              <Form.Item
                label="Instrumento público número"
                name="obligado_solidario_int_publico_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Fecha"
                name="obligado_solidario_int_publico_fecha"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Nombre"
                name="obligado_solidario_int_publico_name"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label="Numero"
                name="obligado_solidario_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Localidad"
                name="obligado_solidario_int_publico_localidad"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Folio mercantil numero"
                name="obligado_solidario_int_publico_folio_mercantil_num"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={7} offset={8}>
              <Form.Item
                label="Registro publico"
                name="obligado_solidario_int_publico_registro_publico"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Divider orientation="center" style={dividerStyle}>
              Datos de escritura publica
            </Divider>
            <Col span={8}>
              <Form.Item
                label="Instrumento público
                número"
                name="obligado_solidario_escritura_publica_int_publico_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Fecha"
                name="obligado_solidario_escritura_publica_fecha"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Nombre"
                name="obligado_solidario_escritura_publica_name"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Numero"
                name="obligado_solidario_escritura_publica_numero"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Localidad"
                name="obligado_solidario_escritura_publica_localidad"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Divider orientation="center" style={dividerStyle}>
              Datos de inscripción
            </Divider>
            <Col span={12}>
              <Form.Item
                label="Folio mercantil numero"
                name="obligado_solidario_datos_inscripcion_folio_mercantil_num"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Registro publico"
                name="obligado_solidario_datos_inscripcion_registro_publico"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Form.Item
                label="objeto social principal"
                name="obligado_solidario_objeto_social_principal"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="RFC"
                name="obligado_solidario_rfc"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Domicilio"
                name="obligado_solidario_domicilio"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Telefono"
                name="obligado_solidario_telefono"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="obligado_solidario_correo"
                rules={[{ required: true, message: 'Campo requerido' }]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </Form>
  );
};

ContractsForm.propTypes = {
  formRef: PropTypes.object,
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
