import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { FIND, process } from '../Service/Api';
import { apiKey, getServer } from '../Utils/url';
import axios from 'axios';

export const useFetchMethodsPay = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getMethodsPay = useCallback(async () => {
    setLoading(true);
    const response = await process(FIND, 'payment-methods', {}, params);
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar Formas de Pago Sucursal');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getMethodsPay();
  }, [getMethodsPay]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getMethodsPay();

  return [data, loading, onChangeParams, update];
};

export const useFetchMethodsPayWithApiKey = () => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const baseURL = getServer();
  const key = apiKey();

  const getMethodsPay = useCallback(async () => {
    setLoading(true);
    let response;
    try {
      const config = {
        method: 'get',
        maxBodyLength: 'Infinity',
        url: `${baseURL}/payment-methods`,
        headers: {
          'x-api-key': key,
        },
      };
      response = await axios(config);
    } catch (error) {
      message.error('Error al intentar obtener los metodos de pago');
    }
    if (response) {
      setData(response.data);
    } else {
      message.error('Error al consultar metodos de pago');
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getMethodsPay();
  }, [getMethodsPay]);

  const update = () => getMethodsPay();

  return [data, loading, update];
};
