export const aSearchElements = [
  {
    label: '',
    name: 'branch_id',
    type: 'invisible',
  },
  {
    label: 'Serie',
    name: 'serie',
    type: 'input-fixed',
  },
  {
    label: 'Folio',
    name: 'folio',
    type: 'input-int',
  },
];

export const oInitState = {
  serie: '',
  folio: '',
};

export const requiredFields = true;
