import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Descriptions } from 'antd';
import { EmailForm, TicketForm } from '../Forms';

export const TicketActions = ({
  fn = {
    handler: () => ({}),
    handlerSelected: () => ({}),
    selected: {},
    oClient: {},
  },
  modal = {
    form: {},
    handler: () => ({}),
    loading: false,
    submit: () => ({}),
    visible: false,
  },
  modal2 = {
    form: {},
    handler: () => ({}),
    loading: false,
    submit: () => ({}),
    visible: false,
  },
}) => {
  return modal2?.visible ? (
    <Modal
      cancelText="Cancelar"
      centered
      destroyOnClose
      onCancel={() => {
        fn.handlerSelected({});
        modal2.handler(false);
        modal2.form.resetFields();
      }}
      onOk={() => modal2.form.submit()}
      okText={fn.selected?.id ? 'Enviar ' : 'Enviar'}
      okButtonProps={{
        loading: modal2.loading,
      }}
      title={`Reenviar Factura ${fn.selected.serie}-${fn.selected.folio}`}
      visible={modal2.visible}
    >
      <EmailForm formRef={modal2.form} onSubmit={modal2.submit} />
    </Modal>
  ) : (
    <Modal
      cancelText="Cancelar"
      centered
      destroyOnClose
      onCancel={() => {
        fn.handlerSelected({});
        modal.handler(false);
        modal.form.resetFields();
      }}
      onOk={() => modal.form.submit()}
      okText={fn.selected?.id ? 'Timbrar ' : 'Timbrar'}
      okButtonProps={{
        loading: modal.loading,
      }}
      title={`Crear Factura de Ticket ${fn.selected.serie}-${fn.selected.folio}`}
      visible={modal.visible}
    >
      <Descriptions title="Factura">
        <Descriptions.Item label="Subtotal">
          {fn.selected.total_bruto}
        </Descriptions.Item>
        <Descriptions.Item label="IVA">
          {(fn.selected.total_bruto * 0.16).toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {(fn.selected.total_bruto * 1.16).toFixed(2)}
        </Descriptions.Item>
      </Descriptions>

      <TicketForm
        formRef={modal.form}
        onSubmit={modal.submit}
        oClient={fn.oClient}
      />
    </Modal>
  );
};

TicketActions.propTypes = {
  fn: PropTypes.any.isRequired,
  modal: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
    oClient: PropTypes.object,
  }),
  modal2: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
    oClient: PropTypes.object,
  }),
};
