import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Col, Form, Row, Select, Input } from 'antd';
import { SearchSelector } from '../Atoms/SearchSelector';
import {
  useFetchFormsSATWithApiKey,
  useFetchMethodsPayWithApiKey,
} from '../../Hooks';
import { generateQueries } from '../../Utils/query';

const formSearch = [
  {
    name: 'description',
    type: 'input',
    whitelist: true,
  },
  {
    name: 'status',
    type: 'select',
  },
];

export const TicketForm = ({ formRef, onSubmit, oClient }) => {
  const [formspays, pLoading, fQuery] = useFetchFormsSATWithApiKey();
  const [methods, mLoading] = useFetchMethodsPayWithApiKey();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula una llamada a una API para obtener datos
    const fetchData = async () => {
      setLoading(true);
      // Reemplaza esto con tu llamada a la API
      let email = oClient.email;
      formRef.setFieldsValue({ email });

      setLoading(false);
    };

    fetchData();
  }, [oClient]);

  const handleSearchForms = s => {
    if (s.length > 2) {
      let sQueries = generateQueries({ description: s, status: 1 }, formSearch);
      fQuery(sQueries, 0, 50);
    } else {
      fQuery();
    }
  };

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="InvoiceForm"
      onFinish={onSubmit}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label="Correo Electronico"
            name="email"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" disabled={loading} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Forma de Pago"
            name="payment_form"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <SearchSelector
              allowClear
              handleSearch={debounce(handleSearchForms, 200)}
              loading={pLoading}
              onChange={() => fQuery()}
              placeholder="Seleccionar Forma de pago"
            >
              {formspays?.data.map(oForm => (
                <Select.Option
                  key={oForm.id}
                  value={`${oForm.code}&${oForm.id}`}
                >
                  {oForm.code}-{oForm.description}
                </Select.Option>
              ))}
            </SearchSelector>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="Metodo de Pago"
            name="payment_method"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Select loading={mLoading}>
              {methods?.data.map(
                method =>
                  method.status === 1 && (
                    <Select.Option
                      key={method.id}
                      value={`${method.code}&${method.id}`}
                    >
                      {method.code} {method.description}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

TicketForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
  setLoading: PropTypes.func,
  oOrder: PropTypes.object,
  oClient: PropTypes.object,
};
