import React, { useState } from 'react';
import {
  Button,
  Col,
  message,
  notification,
  Form,
  Row,
  Table,
  Tag,
  Typography,
} from 'antd';
import {
  LoadingOutlined,
  FileDoneOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { SearchBar } from '../Atoms/SearchBar';
import { aSearchElements, oInitState } from './OrderConstants';
import { generateQueries } from '../../Utils/query';
import { useFetchOrders } from '../../Hooks/Order.hook';
import { InvoiceActions } from './InvoiceActions';
import { process, SAVE, UPDATE, GET } from '../../Service/Api';
import { useSelector } from 'react-redux';
import { createContpaq } from '../../Service/ContpaqApi';
import moment from 'moment';

const { Text } = Typography;

export const OrderTable = () => {
  const [search, setSearch] = useState(oInitState);
  const [orders, loading, change, updater] = useFetchOrders();
  const [modal, setModal] = useState(false);
  const [modalSecondModule, setModalSecondModule] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [formRef] = Form.useForm();
  const { user } = useSelector(state => state.auth);

  const aColumns = [
    {
      dataIndex: 'folio',
      title: 'Folio',
    },
    {
      dataIndex: 'serie',
      title: 'Serie',
    },
    {
      dataIndex: 'branch',
      title: 'Sucursal',
      render: ({ trade_name, slug }) => (
        <>
          <Text>{`${trade_name}`}</Text>
          <Text type="secondary">{` (${slug})`}</Text>
        </>
      ),
    },
    {
      dataIndex: 'fecha',
      title: 'Fecha Orden',
      render: fecha => <>{moment(fecha, 'YYYYMMDD').format('L')}</>,
    },
    {
      dataIndex: 'total_bruto',
      title: 'Subtotal',
      render: total_bruto => <>$ {total_bruto.toFixed(2)}</>,
    },
    {
      dataIndex: 'total_neto',
      title: 'Total Neto',
      render: total_neto => <>$ {total_neto.toFixed(2)}</>,
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (_, row) =>
        row.status_invoice === 0 ? (
          <Row gutter={[12, 12]}>
            <Col>
              <Button
                type="primary"
                shape="circle"
                onClick={() => {
                  setSelected(row);
                  formRef.setFieldsValue(row);
                  setModalSecondModule(true);
                }}
              >
                <EditOutlined />
              </Button>
            </Col>
            {row.status === 1 ? (
              <Col>
                <Button
                  shape="circle"
                  onClick={() => {
                    setSelected(row);
                    setModal(true);
                  }}
                >
                  <FileDoneOutlined />
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        ) : (
          <Row>
            <Tag color="#7444C8">Facturada</Tag>
          </Row>
        ),
    },
  ];

  const handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const handleSubmit = async values => {
    setModalLoading(true);
    let res = await process(GET, 'clients', {}, { id: values.cliente });
    let oCompany = await process(GET, 'companies', {}, { id: user.company_id });
    let oPaymentMethod = await process(
      GET,
      'payment-methods',
      {},
      { id: values?.payment_method }
    );

    let oPaymentForm = await process(
      GET,
      'payment-forms',
      {},
      { id: values?.payment_form }
    );
    let responseContpaq;
    debugger; // eslint-disable-line no-debugger

    let oContpaq = {
      codeClient: res?.data?.contpaq,
      codeCompany: oCompany?.data?.rfc,
      email: values.email,
      subtotal: selected.total_bruto,
      metodoPago: oPaymentMethod?.data?.code,
      formaPago: oPaymentForm?.data?.code,
    };

    responseContpaq = await createContpaq('restaurant/invoice', oContpaq);

    // Console.log(responseContpaq);

    if (responseContpaq?.ok && responseContpaq.status === 200) {
      if (responseContpaq?.data?.statusCode !== 200) {
        console.log('Error : ', responseContpaq?.data?.value);
        message.error(`Error en contpaq ${responseContpaq?.data?.value}`);
        setModalLoading(false);
        return;
      } else {
        let dataResponse = JSON.parse(responseContpaq.data.value);
        let oInvoice = {
          order_id: selected.id,
          branch_id: selected.branch_id,
          client_id: values.cliente,
          use_id: res.data.use_id,
          form_id: values.payment_form,
          method_id: values.payment_method,
          invoicing_date: moment().format(),
          stamping_date: moment().format(),
          subtotal: selected.total_bruto,
          cfdi: responseContpaq?.data?.value,
          serie: dataResponse.serie,
          folio: `${dataResponse.folio}`,
          stamping_state: 1,
          status: 1,
          sat_uuid: dataResponse.folioDigital.uuid,
          complements: `${dataResponse.folioDigital.uuid}`,
          qr: `${dataResponse.folioDigital.uuid}`,
          errors: `${dataResponse.folioDigital.uuid}`,
        };

        let response = await process(SAVE, 'invoices', oInvoice);
        setModalLoading(false);
        if (response?.ok) {
          message.success('Exito');
          formRef.resetFields();
          let newResponse = await process(
            UPDATE,
            'orders',
            { status_invoice: 1 },
            {
              id: selected.id,
            }
          );

          if (newResponse?.ok) {
            setSelected({});

            setModal(false);
            updater();
          } else {
            const { data } = response;
            if (data?.code === 400) {
              let sErrors = '';
              if (Array.isArray(data.errors)) {
                for (const oError of data.errors) {
                  if (oError.type === 'unique violation') {
                    sErrors += `El valor ${oError.path} ya existe en BD\n`;
                  }
                }
              } else {
                for (const sKey in data?.errors) {
                  sErrors += data.errors[sKey] + '\n';
                }
              }
              message.error(data?.message);
              if (sErrors !== '') {
                notification.error({
                  message: sErrors,
                  title: 'Errores',
                });
              }
            } else if (data?.code === 409) {
              message.error('Valor duplicado');
            } else {
              message.error('Error en ');
            }
          }
        } else {
          const { data } = response;
          if (data?.code === 400) {
            let sErrors = '';
            if (Array.isArray(data.errors)) {
              for (const oError of data.errors) {
                if (oError.type === 'unique violation') {
                  sErrors += `El valor ${oError.path} ya existe en BD\n`;
                }
              }
            } else {
              for (const sKey in data?.errors) {
                sErrors += data.errors[sKey] + '\n';
              }
            }
            message.error(data?.message);
            if (sErrors !== '') {
              notification.error({
                message: sErrors,
                title: 'Errores',
              });
            }
          } else if (data?.code === 409) {
            message.error('Valor duplicado');
          } else {
            message.error('Error en ');
          }
        }
      }
    }
  };

  const generateCode = long => {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < long) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const handleOrders = async values => {
    setModalLoading(true);
    let response;
    if (selected?.id) {
      let updated_by = user.id;
      let payload = {
        ...values,
        updated_by,
      };
      response = await process(UPDATE, 'orders', payload, {
        id: selected.id,
      });
    } else {
      let detelle_ticket = [];
      let detelle_pago = [];
      let created_by = user.id;
      let status_invoice = 3;
      let sCodigo = generateCode(15);

      const ALIASESPERA = '';
      const serie_n = 'B';

      let payload = {
        ...values,
        detelle_ticket,
        detelle_pago,
        status_invoice,
        created_by,
        ALIASESPERA,
        serie_n,
      };
      payload.fecha = new Date(payload.fecha);
      payload.codigo = sCodigo;

      response = await process(SAVE, 'orders', payload);
    }
    setModalLoading(false);
    if (response.ok) {
      setModalSecondModule(false);
      formRef.resetFields();
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en planes');
      }
    }
  };

  const handleSearch = () => change(generateQueries(search, aSearchElements));

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Ordenes</h3>
          <h3>
            <Tag>{orders?.total >= 0 ? orders.total : <LoadingOutlined />}</Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModalSecondModule(true)} type="primary">
            Crear
          </Button>
        </Col>
      </Row>
      <SearchBar
        elements={aSearchElements}
        {...{
          handleReset,
          handleSearch,
          search,
          setSearch,
        }}
      />

      <InvoiceActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: handleSubmit,
          visible: modal,
        }}
        modalSecondModule={{
          form: formRef,
          handler: setModalSecondModule,
          loading: modalLoading,
          submit: handleOrders,
          visible: modalSecondModule,
        }}
      />

      <Table
        columns={aColumns}
        dataSource={orders?.data}
        loading={loading}
        pagination={{
          current: orders?.skip ? orders.skip / 10 + 1 : 1,
          onChange: e =>
            change(
              generateQueries(search, aSearchElements),
              (e - 1) * orders.limit
            ),
          pageSizeOptions: [10],
          total: orders.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
