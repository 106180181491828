/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Row } from 'antd';

export const InitialScreen = () => {
  return (
    <div className="initial-screen">
      <Row justify="space-between" className="cards">
        <p>Bienvenidos</p>
      </Row>
    </div>
  );
};
