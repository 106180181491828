import {
  Col,
  Row,
  Tag,
  Form,
  Table,
  Button,
  message,
  Popconfirm,
  notification,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import React, { useState } from 'react';
import { SearchBar } from '../Atoms/SearchBar';
import { ClientActions } from './ClientActions';
import { generateQueries } from '../../Utils/query';
import { createContpaq } from '../../Service/ContpaqApi';
import { useFetchClients } from '../../Hooks/Client.hook';
import { aSearchElements, oInitState } from './ClientConstants';
import { process, GET, FIND, SAVE, UPDATE } from '../../Service/Api';
import { validarRFC } from '../../Utils/checkRFC';

export const ClientTable = () => {
  const [formRef] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [oAddress, setAddress] = useState({});
  const [selected, setSelected] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [modalLoading, setModalLoading] = useState(false);
  const [clients, loading, change, updater] = useFetchClients();

  const handleZipCode = newZipCode => {
    setZipCode(newZipCode);
  };

  const handleIsValid = newIsValid => {
    setIsValid(newIsValid);
  };

  const handleAddress = newAddress => {
    setAddress(newAddress);
  };

  const aColumns = [
    {
      dataIndex: 'invoice_name',
      title: 'Nombre/Razón Social',
    },
    {
      dataIndex: 'rfc',
      title: 'RFC',
    },
    {
      dataIndex: 'regime',
      title: 'Regimen Fiscal',
      render: regime => {
        return (
          <>
            {regime.code}-{regime.description}
          </>
        );
      },
    },

    {
      dataIndex: 'invoice_use',
      title: 'Uso CFDI',
      render: oUse => {
        return (
          <>
            {oUse.code}-{oUse.description}
          </>
        );
      },
    },

    {
      dataIndex: 'branch',
      title: 'Sucursal',
      render: oBranch => {
        return <>{oBranch.trade_name}</>;
      },
    },

    {
      dataIndex: 'zipcode',
      title: 'Codigo Postal',
    },

    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Button
              onClick={() => {
                setSelected(row);
                formRef.setFieldsValue(row);
                setModal(true);
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              onConfirm={() => _handleDeactivate(row.id)}
              title="Esta seguro de desactivar este cliente?"
            >
              <Button>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        );
      },
    },
  ];

  const handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const handleSearch = () => change(generateQueries(search, aSearchElements));

  const handleSubmit = async values => {
    let response;
    let responseContpaq;
    setModalLoading(true);

    const { rfc } = values;
    if (!validarRFC(rfc)) {
      message.error('RFC no valido');
      setModalLoading(false);
      return;
    }

    let oCompany = await process(GET, 'branches', {}, { id: values.branch_id });
    let oClient = await process(
      FIND,
      'clients',
      {},
      { queries: `rfc=${values.rfc}`, skip: 0 }
    );

    if (oClient.data.total === 0 || selected?.id) {
      let oRegime = await process(GET, 'regimes', {}, { id: values.regime_id });
      let oUses = await process(GET, 'invoice-uses', {}, { id: values.use_id });

      if (oCompany?.ok && zipCode !== '' && isValid && oAddress) {
        let contpaqCode = values.rfc.substring(0, 9);
        let oContpaq = {
          codigo: contpaqCode,
          codeCompany: oCompany?.data.rfc.toUpperCase(),
          rfc: values.rfc.toUpperCase(),
          razonSocial: values.invoice_name.toUpperCase(),
          usoCFDI: oUses.data.code,
          regimen: `${oRegime.data.code}`,
          cNombreCalle: oAddress.street.toUpperCase(),
          exterior: oAddress.ext ? oAddress.ext : 'SN',
          interior: oAddress.inte ? oAddress.inte : 'SN',
          cColonia: oAddress.neighborhood.toUpperCase(),
          cCiudad: oAddress.city.toUpperCase(),
          cEstado: oAddress.state.toUpperCase(),
          cPais: oAddress.country.toUpperCase(),
          cCodigoPostal: zipCode,
        };

        if (selected?.id) {
          responseContpaq = await createContpaq(
            'restaurant/client/update',
            oContpaq
          );
        } else {
          responseContpaq = await createContpaq('restaurant/client', oContpaq);
        }

        if (responseContpaq?.ok) {
          if (responseContpaq.status === 200) {
            if (oAddress.inte === null || oAddress.inte === '') {
              delete oAddress.inte;
            }
            let oSend = {
              ...oAddress,
              ...values,
              zipcode: zipCode,
              contpaq: contpaqCode,
            };
            if (selected?.id) {
              response = await process(UPDATE, 'clients', oSend, {
                id: selected.id,
              });
            } else {
              response = await process(SAVE, 'clients', oSend);
            }
            setModalLoading(false);
            if (response?.ok) {
              message.success('Exito');
              formRef.resetFields();
              setSelected({});
              setModal(false);
              updater();
            } else {
              const { data } = response;
              if (data?.code === 400) {
                let sErrors = '';
                if (Array.isArray(data.errors)) {
                  for (const oError of data.errors) {
                    if (oError.type === 'unique violation') {
                      sErrors += `El valor ${oError.path} ya existe en BD\n`;
                    }
                  }
                } else {
                  for (const sKey in data?.errors) {
                    sErrors += data.errors[sKey] + '\n';
                  }
                }
                message.error('Error del servidor', data?.message);
                if (sErrors !== '') {
                  notification.error({
                    message: sErrors,
                    title: 'Errores',
                  });
                }
              } else if (data?.code === 409) {
                message.error('Valor duplicado');
              } else {
                message.error('Error en clientes');
              }
            }
          } else {
            message.error('Error de compaqt', responseContpaq.data.value);
          }
        }
        setModalLoading(false);
      }
    } else {
      message.error('Ya existe un cliente con el RFC');
      setModalLoading(false);
    }
  };

  const _handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'clients',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Clientes</h3>
          <h3>
            <Tag>
              {clients?.total >= 0 ? clients.total : <LoadingOutlined />}
            </Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear <UserAddOutlined />
          </Button>
        </Col>
      </Row>
      <ClientActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: handleSubmit,
          visible: modal,
        }}
        onZipCode={handleZipCode}
        onIsValid={handleIsValid}
        onAddress={handleAddress}
      />
      <SearchBar
        elements={aSearchElements}
        {...{
          handleReset,
          handleSearch,
          search,
          setSearch,
        }}
      />
      <Table
        columns={aColumns}
        dataSource={clients?.data}
        loading={loading}
        pagination={{
          current: clients?.skip ? clients.skip / 10 + 1 : 1,
          onChange: e =>
            change(
              generateQueries(search, aSearchElements),
              (e - 1) * clients.limit
            ),
          pageSizeOptions: [10],
          total: clients.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
