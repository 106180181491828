import React from 'react';
import { TicketTable } from '../../Components/Tickets/TicketTable';

export const Tickets = () => {
  return (
    <div className="tickets-screen">
      <TicketTable />
    </div>
  );
};
