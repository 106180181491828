import React from 'react';
import { ProfileTable } from '../../Components/Profile/ProfileTable';

export const ProfileScreen = () => {
  return (
    <div className="payment-screen">
      <ProfileTable />
    </div>
  );
};
