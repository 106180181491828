import React from 'react';
import { BranchTable } from '../../Components/Branches/BranchTable';

export const BranchesScreen = () => {
  return (
    <div className="Branch-scren">
      <BranchTable />
    </div>
  );
};
