import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row } from 'antd';

export const EmailForm = ({ formRef, onSubmit }) => {
  return (
    <Form form={formRef} layout="vertical" name="EmailForm" onFinish={onSubmit}>
      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

EmailForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
