import React from 'react';
import { OrderTable } from '../../Components/Orders/OrderTable';

export const OrdersScreen = () => {
  return (
    <div className="orders-screen">
      <OrderTable />
    </div>
  );
};
