/**
 * Valida el RFC de una persona física o moral
 * @param {*} rfc
 * @returns
 */
export function validarRFC(rfc) {
  const regex = /^([A-ZÑ&]{3,4})(\d{2})(\d{2})(\d{2})([A-Z\d]{2})([A\d])?$/i;

  if (!regex.test(rfc)) {
    return false;
  }

  const match = rfc.match(regex);
  const year = parseInt(match[2], 10);
  const month = parseInt(match[3], 10);
  const day = parseInt(match[4], 10);

  return esFechaValida(year, month, day);
}

/**
 * Valida si una fecha es válida
 * @param {*} y
 * @param {*} m
 * @param {*} d
 * @returns {boolean}
 */
function esFechaValida(y, m, d) {
  y += y < 50 ? 2000 : 1900;
  const date = new Date(y, m - 1, d);
  return date && date.getMonth() + 1 === m && date.getDate() === d;
}
