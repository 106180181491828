import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { useFetchRegimes } from '../../Hooks/Regime.hook';

export const BranchForm = ({ formRef, onSubmit }) => {
  const [regimes, loading] = useFetchRegimes();

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="BranchForm"
      onFinish={onSubmit}
    >
      <Row gutter={[24]}>
        <Col span={24}>
          <Form.Item
            label="Razon Social"
            name="trade_name"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Razon Social Facturacion"
            name="invoice_name"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="RFC"
            name="rfc"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Regimen Fiscal" name="regime_id">
            <Select loading={loading}>
              {regimes?.data.map(
                oRegime =>
                  oRegime.status === 1 && (
                    <Select.Option key={oRegime.id} value={oRegime.id}>
                      {oRegime.description} {oRegime.code}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Codigo Postal"
            name="zipcode"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Dirección"
            name="address"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Col span={12}>
        <Form.Item
          label="Identificador"
          name="slug"
          rules={[{ required: true, message: 'Campo requerido' }]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Telefono"
            name="phone"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="Estatus" name="status">
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

BranchForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
