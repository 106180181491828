import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { FIND, process } from '../Service/Api';
import { apiKey, getServer } from '../Utils/url';
import axios from 'axios';

export const useFetchClients = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getClients = useCallback(async () => {
    setLoading(true);
    const response = await process(FIND, 'clients', {}, params);
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar clientes');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getClients();

  return [data, loading, onChangeParams, update];
};

export const useFetchClientsWithApiKey = async (queries = '') => {
  const baseURL = getServer();
  const key = apiKey();

  let response;
  try {
    const config = {
      method: 'get',
      maxBodyLength: 'Infinity',
      url: `${baseURL}/clients?${queries ? queries : ''}`,
      headers: {
        'x-api-key': key,
      },
    };
    response = await axios(config);
  } catch (error) {
    message.error('Error al intentar obtener cliente');
  }
  if (response) {
    return response;
  } else {
    message.error('Error al consultar cliente');
  }
};

export const useSaveClientsWithApiKey = async userData => {
  const baseURL = getServer();
  const key = apiKey();

  let response;
  try {
    const config = {
      method: 'post',
      maxBodyLength: 'Infinity',
      url: `${baseURL}/clients`,
      headers: {
        'x-api-key': key,
      },
      data: userData,
    };
    response = await axios(config);
  } catch (error) {
    message.error('Error al intentar crear cliente');
  }
  if (response) {
    return response;
  } else {
    message.error('Error al crear cliente');
  }
};
