import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

import {
  ClientsScreen,
  BranchesScreen,
  InitialScreen,
  InvoicesScreen,
  ProfileScreen,
  RegimeScreen,
  RolesScreen,
  UsersScreen,
  FormsPaymentScreen,
  BranchesPaymentScreen,
  OrdersScreen,
} from '../../Screens';

const SubRouter = ({ match: { url }, locations }) => {
  return (
    <Switch>
      <Route
        component={UsersScreen}
        exact
        location={locations}
        path={`${url}/users`}
      />
      <Route
        component={RolesScreen}
        exact
        location={locations}
        path={`${url}/roles`}
      />
      <Route
        component={InitialScreen}
        exact
        location={locations}
        path={`${url}`}
      />
      <Route
        component={BranchesScreen}
        exact
        location={locations}
        path={`${url}/branches`}
      />
      <Route
        component={ClientsScreen}
        exact
        location={locations}
        path={`${url}/clients`}
      />

      <Route
        component={InvoicesScreen}
        exact
        location={locations}
        path={`${url}/invoices`}
      />
      <Route
        component={ProfileScreen}
        exact
        location={locations}
        path={`${url}/profile`}
      />
      <Route
        component={RegimeScreen}
        exact
        location={locations}
        path={`${url}/regimes`}
      />
      <Route
        component={FormsPaymentScreen}
        exact
        location={locations}
        path={`${url}/forms-payment`}
      />
      <Route
        component={BranchesPaymentScreen}
        exact
        location={locations}
        path={`${url}/branches-payment`}
      />
      <Route
        component={OrdersScreen}
        exact
        location={locations}
        path={`${url}/orders`}
      />
    </Switch>
  );
};

SubRouter.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  locations: PropTypes.object,
};

export default withRouter(SubRouter);
