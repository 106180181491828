import React from 'react';
import NewClientForm from '../../Components/Forms/NewClient.form';

export const NewClient = () => {
  return (
    <div className="newClient-screen">
      <NewClientForm />
    </div>
  );
};
