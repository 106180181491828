import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Card, Typography, Button, Row, Col, notification } from 'antd';
import logo from '../../Assets/img/logo.svg';
import {
  ArrowLeftOutlined,
  FileProtectOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { getServer } from '../../Utils/url';
import { create } from 'apisauce';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const API = create({
  baseURL: getServer(),
});

const { Title, Text } = Typography;

const ContractSigningForm = () => {
  const canvasRef = useRef(null);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const clearSignature = () => {
    canvasRef.current.clear();
  };

  // eslint-disable-next-line no-undef
  const query = new URLSearchParams(window.location.search);

  const onSubmit = async () => {
    setLoading(true);
    const signature = canvasRef.current.toDataURL();
    const aval = query.get('aval');
    const auth = query.get('q');
    const invoices_id = query.get('id');
    const signatureFieldName = aval === 'true' ? 'signature_aval' : 'signature';
    const data = { [signatureFieldName]: signature };
    const response = await API.patch(
      `/signature-validator/${auth}`,
      { ...data, aval, invoices_id },
      {
        headers: {
          Authorization: `BEARER ${auth}`,
        },
      }
    );
    if (response?.ok) {
      notification.success({
        description: 'Su firma fue registrada correctamente',
      });
    } else {
      notification.error({
        message: response.data.message,
        description: 'Ocurrió un error',
      });
    }
    setLoading(false);
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#001529',
        color: '#fff',
      }}
    >
      {redirect && <Redirect to="/" />}
      <div style={{ width: '80%', maxWidth: '600px' }}>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <img src={logo} />
          </Col>
        </Row>
        <Card>
          <div style={{ textAlign: 'center' }}>
            <Title level={4} style={{ marginBottom: '1rem' }}>
              Firma Digital <FileProtectOutlined />
            </Title>
            <Text style={{ marginBottom: '3rem' }}>
              La firma ingresada en este campo se utilizará para los contratos
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              width: '100%',
              marginTop: '3rem',
            }}
          >
            <SignatureCanvas
              minWidth={0.5}
              penColor="black" // Color del lápiz
              canvasProps={{
                className: 'firma-canvas',
                style: { background: '#EAE8E8' },
              }}
              ref={canvasRef}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '0.4rem',
              }}
            >
              <Button onClick={clearSignature} danger icon={<DeleteOutlined />}>
                Borrar
              </Button>
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: '#BAEE55',
                  borderColor: '#BAEE55',
                  color: '#fff',
                }}
                onClick={onSubmit}
                loading={loading}
              >
                Guardar Firma
              </Button>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Link
              to="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#000',
              }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: '0.5rem', transform: 'rotate(180deg)' }}
              />
              <span>Volver a Pampas</span>
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ContractSigningForm;
