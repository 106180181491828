import React from 'react';
import {
  AppstoreOutlined,
  ShopOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  HomeOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  UserAddOutlined,
} from '@ant-design/icons';

export const Routes = [
  {
    title: 'Home',
    slug: 'home',
    path: '/',
    icon: <HomeOutlined className="anticon" />,
    roles: ['admin', 'test', 'client'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Sucursales',
    slug: 'branches',
    path: '/branches',
    icon: <ShopOutlined className="anticon" />,
    roles: ['admin', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Ordenes',
    slug: 'orders',
    path: '/orders',
    icon: <ShoppingOutlined className="anticon" />,
    roles: ['admin', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Facturas',
    slug: 'invoices',
    path: '/invoices',
    icon: <ShoppingOutlined className="anticon" />,
    roles: ['admin', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Clientes',
    slug: 'clients',
    path: '/clients',
    icon: <UsergroupDeleteOutlined className="anticon" />,
    roles: ['admin', 'company', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Roles',
    slug: 'roles',
    path: '/roles',
    icon: <SolutionOutlined className="anticon" />,
    roles: ['admin', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Users',
    slug: 'users',
    path: '/users',
    icon: <UserAddOutlined className="anticon" />,
    roles: ['admin', 'test'],
    childs: [],
    optionalIcon: true,
  },
  {
    title: 'Profile',
    slug: 'profile',
    path: '/profile',
    icon: <UserOutlined className="anticon" />,
    roles: ['admin', 'company', 'user', 'test', 'client'],
    childs: [],
    optionalIcon: true,
  },

  {
    title: 'Catalogos SAT',
    slug: 'catalogs',
    path: '',
    icon: <AppstoreOutlined />,
    roles: ['admin', 'company', 'test', 'client'],
    childs: [
      {
        title: 'Regimenes',
        slug: 'regimes',
        path: '/regimes',
        roles: ['admin', 'company', 'test', 'client'],
        childs: [],
        optionalIcon: true,
      },
      {
        title: 'Formas de pago',
        slug: 'forms-payment',
        path: '/forms-payment',
        roles: ['admin', 'company', 'test', 'client'],
        childs: [],
        optionalIcon: true,
      },
    ],
    optionalIcon: true,
  },
];
