import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { FIND, FIND_KEY, process } from '../Service/Api';
import { apiKey, getServer } from '../Utils/url';
import axios from 'axios';

export const useFetchBranch = (queries = '', skip = 0, limit = 10) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getBranch = useCallback(async () => {
    setLoading(true);
    const response = await process(FIND, 'branches', {}, params);
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar branches');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getBranch();
  }, [getBranch]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getBranch();

  return [data, loading, onChangeParams, update];
};

export const useFetchBranchWithApiKey = (
  queries = '',
  skip = 0,
  limit = 10
) => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ queries, limit, skip });

  const getBranch = useCallback(async () => {
    setLoading(true);
    const response = await process(FIND_KEY, 'branches', {}, params);
    if (response?.ok) {
      setData(response.data);
    } else {
      message.error('Error al consultar branches');
    }
    setLoading(false);
  }, [process, params]);

  useEffect(() => {
    getBranch();
  }, [getBranch]);

  const onChangeParams = (q = '', s = 0, l = 10) =>
    setParams({ queries: q, limit: l, skip: s });

  const update = () => getBranch();

  return [data, loading, onChangeParams, update];
};

export const useFetchOneBranchWithApiKey = async (branch_id = '') => {
  const baseURL = getServer();
  const key = apiKey();

  let response;
  try {
    const config = {
      method: 'get',
      maxBodyLength: 'Infinity',
      url: `${baseURL}/branches?id=${branch_id}`,
      headers: {
        'x-api-key': key,
      },
    };
    response = await axios(config);
  } catch (error) {
    message.error('Error al intentar obtener la sucursal');
  }
  if (response) {
    return response.data.data[0];
  } else {
    message.error('Error al consultar la sucursal');
  }
};
