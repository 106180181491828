import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { InvoiceCancelForm, EmailForm } from '../Forms/';

export const InvoiceActions = ({
  fn = {
    handler: () => ({}),
    handlerSelected: () => ({}),
    selected: {},
  },
  modal = {
    form: {},
    handler: () => ({}),
    loading: false,
    submit: () => ({}),
    visible: false,
  },
  modalSecondModule = {
    form: {},
    handler: () => ({}),
    loading: false,
    submit: () => ({}),
    visible: false,
  },
}) => {
  return modalSecondModule?.visible ? (
    <Modal
      cancelText="Cancelar"
      centered
      destroyOnClose
      onCancel={() => {
        fn.handlerSelected({});
        modalSecondModule.handler(false);
        modalSecondModule.form.resetFields();
      }}
      onOk={() => modalSecondModule.form.submit()}
      okText={'Enviar'}
      okButtonProps={{
        loading: modalSecondModule.loading,
      }}
      title={'Reenvio de Correo'}
      visible={modalSecondModule.visible}
      width={800}
    >
      <EmailForm
        formRef={modalSecondModule.form}
        onSubmit={modalSecondModule.submit}
      />
    </Modal>
  ) : (
    <Modal
      cancelText="Volver"
      centered
      destroyOnClose
      onCancel={() => {
        fn.handlerSelected({});
        modal.handler(false);
        modal.form.resetFields();
      }}
      onOk={() => modal.form.submit()}
      okText={'Cancelar factura'}
      okButtonProps={{
        loading: modal.loading,
      }}
      title={'Cancelar factura'}
      visible={modal.visible}
    >
      <InvoiceCancelForm
        formRef={modal.form}
        onSubmit={modal.submit}
        setLoading={fn.handler}
      />
    </Modal>
  );
};

InvoiceActions.propTypes = {
  fn: PropTypes.any.isRequired,
  modal: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
  }),
  modalSecondModule: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
  }),
};
