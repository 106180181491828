import React from 'react';
import { RegimeTable } from '../../Components/Regimes/RegimeTable';

export const RegimeScreen = () => {
  return (
    <div className="regime-screen">
      <RegimeTable />
    </div>
  );
};
