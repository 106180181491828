import { create } from 'apisauce';

// Define the api
const API = create({
  baseURL: 'http://4.227.152.233:5000',
});

export async function loginContpaq() {
  return await API.post('/users/login', {
    email: 'beto_jpt@hotmail.com',
    password: 'mAf3rciscot3@',
  });
}

export async function createContpaq(model, payload) {
  let oToken = await loginContpaq();

  const oAuth = {
    headers: {
      Authorization: `BEARER ${oToken.data.token}`,
    },
  };

  return await API.post(`/${model}`, payload, oAuth);
}
