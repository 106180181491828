import React, { useState } from 'react';
import {
  Button,
  Col,
  Badge,
  Row,
  Table,
  Tag,
  Form,
  message,
  Tooltip,
  notification,
} from 'antd';
import {
  CloseOutlined,
  SendOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
//Import PropTypes from 'prop-types';
import { SearchBar } from '../Atoms/SearchBar';
// eslint-disable-next-line no-unused-vars
import { aSearchElements, oInitState, cancelMotives } from './InvoiceConstants';
import { generateQueries } from '../../Utils/query';
import { useFetchInvoices } from '../../Hooks/Invoice.hook';
import { InvoiceActions } from './InvoiceActions.js';
import { process, GET, UPDATE } from '../../Service/Api';
import { createContpaq } from '../../Service/ContpaqApi';

export const InvoiceTable = () => {
  const [search, setSearch] = useState(oInitState);
  const [invoices, loading, change, updater] = useFetchInvoices();
  const [formRef] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [modalSecondModule, setModalSecondModule] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selected, setSelected] = useState({});

  const aColumns = [
    {
      dataIndex: 'folio',
      title: 'Folio',
    },
    {
      dataIndex: 'serie',
      title: 'Serie',
    },
    {
      dataIndex: 'client',
      title: 'Cliente',
      render: client => {
        return <>{client.invoice_name}</>;
      },
    },
    {
      dataIndex: 'invoice_use',
      title: 'Uso CFDI',
      render: invoice_use => {
        return <>{invoice_use.description}</>;
      },
    },
    {
      dataIndex: 'payment_method',
      title: 'Metodo de Pago',
      render: payment_method => {
        return <>{payment_method.description}</>;
      },
    },
    {
      dataIndex: 'payment_form',
      title: 'Forma de Pago',
      render: payment_form => {
        return <>{payment_form.description}</>;
      },
    },
    {
      dataIndex: 'subtotal',
      title: 'Sub total',
      render: subtotal => {
        return <>${subtotal}</>;
      },
    },

    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status === 2 ? (
          <Tag color="#ed2828">Cancelada</Tag>
        ) : status === 0 ? (
          <Tag color="#f50">Pendiente</Tag>
        ) : (
          <Tag color="#7BC342">Timbrada</Tag>
        ),
    },
    {
      dataIndex: '_id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            {row.status === 2 ? (
              <Tag color="#ed2828">Cancelada</Tag>
            ) : row.stamping_state === 1 ? (
              <Row gutter={[12, 12]}>
                <Col>
                  <Tooltip title="Enviar Correo">
                    <Button
                      type="primary"
                      shape="round"
                      icon={<SendOutlined />}
                      onClick={() => {
                        console.log('1', row);
                        setSelected(row);
                        formRef.setFieldsValue(row);
                        setModalSecondModule(true);
                      }}
                    >
                      Enviar
                    </Button>
                  </Tooltip>
                </Col>
                <Col>
                  <Tooltip title="Cancelar Factura">
                    <Button
                      type="primary"
                      danger
                      shape="round"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        console.log('1', row);
                        setSelected(row);
                        formRef.setFieldsValue(row);
                        setModal(true);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            ) : (
              <Badge status="error" text="CANCELADA" />
            )}
          </Row>
        );
      },
    },
  ];

  const handleReset = () => {
    setSearch(oInitState);
    change();
  };
  const handleSearch = () => change(generateQueries(search, aSearchElements));

  const handleSubmit = async values => {
    setModalLoading(true);
    let oBranch = await process(
      GET,
      'branches',
      {},
      { id: selected.branch_id }
    );
    let responseContpaq;
    let oContpaq = {
      codeCompany: oBranch?.data?.rfc,
      motivo: values.motive,
      serie: selected.serie,
      folio: selected.folio,
    };

    responseContpaq = await createContpaq(
      'restaurant/invoice/cancel',
      oContpaq
    );
    if (responseContpaq?.ok && responseContpaq.status === 200) {
      if (responseContpaq?.data?.statusCode !== 200) {
        console.log('Error : ', responseContpaq?.data);
        message.error(`Error en contpaq `);
        setModalLoading(false);
        return;
      } else {
        let oInvoice = {
          stamping_state: 2,
          status: 1,
        };

        let response = await process(UPDATE, 'invoices', oInvoice, {
          id: selected.id,
        });

        setModalLoading(false);
        if (response?.ok) {
          message.success('Exito Factura Cancelada');
          formRef.resetFields();
          let newResponse = await process(
            UPDATE,
            'orders',
            { status_invoice: 0, status: 1 },
            {
              id: selected.order_id,
            }
          );

          if (newResponse?.ok) {
            setSelected({});

            setModal(false);
            updater();
          } else {
            const { data } = response;
            if (data?.code === 400) {
              let sErrors = '';
              if (Array.isArray(data.errors)) {
                for (const oError of data.errors) {
                  if (oError.type === 'unique violation') {
                    sErrors += `El valor ${oError.path} ya existe en BD\n`;
                  }
                }
              } else {
                for (const sKey in data?.errors) {
                  sErrors += data.errors[sKey] + '\n';
                }
              }
              message.error(data?.message);
              if (sErrors !== '') {
                notification.error({
                  message: sErrors,
                  title: 'Errores',
                });
              }
            } else if (data?.code === 409) {
              message.error('Valor duplicado');
            } else {
              message.error('Error en ');
            }
          }
        } else {
          const { data } = response;
          if (data?.code === 400) {
            let sErrors = '';
            if (Array.isArray(data.errors)) {
              for (const oError of data.errors) {
                if (oError.type === 'unique violation') {
                  sErrors += `El valor ${oError.path} ya existe en BD\n`;
                }
              }
            } else {
              for (const sKey in data?.errors) {
                sErrors += data.errors[sKey] + '\n';
              }
            }
            message.error(data?.message);
            if (sErrors !== '') {
              notification.error({
                message: sErrors,
                title: 'Errores',
              });
            }
          } else if (data?.code === 409) {
            message.error('Valor duplicado');
          } else {
            message.error('Error en ');
          }
        }
      }
    }
  };
  const handleEmail = async values => {
    setModalLoading(true);

    let oBranch = await process(
      GET,
      'branches',
      {},
      { id: selected.branch_id }
    );

    let responseContpaq;
    let oContpaq = {
      codeCompany: oBranch?.data?.rfc,
      email: values.email,
      serie: selected.serie,
      folio: selected.folio,
    };

    responseContpaq = await createContpaq('mail/send', oContpaq);
    if (responseContpaq?.ok && responseContpaq.status === 200) {
      message.success('Exito');
      setModalLoading(false);
      setModalSecondModule(false);
      formRef.resetFields();
    } else {
      message.error('Error al enviar correo');
      setModalLoading(true);
      setModalSecondModule(false);
    }
  };
  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Facturas</h3>
          <h3>
            <Tag>
              {invoices?.total >= 0 ? invoices.total : <LoadingOutlined />}
            </Tag>
          </h3>
        </Col>
      </Row>
      <InvoiceActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: handleSubmit,
          visible: modal,
        }}
        modalSecondModule={{
          form: formRef,
          handler: setModalSecondModule,
          loading: modalLoading,
          submit: handleEmail,
          visible: modalSecondModule,
        }}
      />
      <SearchBar
        elements={aSearchElements}
        {...{
          handleReset,
          handleSearch,
          search,
          setSearch,
        }}
      />
      <Table
        columns={aColumns}
        dataSource={invoices?.data}
        loading={loading}
        pagination={{
          current: invoices?.skip ? invoices.skip / 10 + 1 : 1,
          onChange: e =>
            change(
              generateQueries(search, aSearchElements),
              (e - 1) * invoices.limit
            ),
          pageSizeOptions: [10],
          total: invoices.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
