import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { useFetchBranch } from '../../Hooks/Branch.hook';

export const OrderForm = ({ formRef, onSubmit }) => {
  const [branches, bLoading] = useFetchBranch();

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="BranchForm"
      onFinish={onSubmit}
    >
      <Row gutter={[24]}>
        <Col span={12}>
          <Form.Item
            label="Folio"
            name="folio"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Serie"
            name="serie"
            rules={[
              {
                required: true,
                message: 'Campo requerido',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>
      <Col span={24}>
        <Form.Item
          label="Sucursal"
          name="branch_id"
          rules={[{ required: true, message: 'Campo requerido' }]}
        >
          <Select loading={bLoading}>
            {branches?.data.map(
              oBranch =>
                oBranch.status === 1 && (
                  <Select.Option key={oBranch.id} value={oBranch.id}>
                    {oBranch.trade_name}({oBranch.slug})
                  </Select.Option>
                )
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Fecha de la Orden"
          name="fecha"
          rules={[{ required: true, message: 'Campo requerido' }]}
        >
          <Input type="date" />
        </Form.Item>
      </Col>

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Subtotal"
            name="total_bruto"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Total"
            name="total_neto"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Estatus"
            name="status"
            initialValue={1}
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Select>
              <Select.Option value={0}>Inactivo</Select.Option>
              <Select.Option value={1}>Activo</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

OrderForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
