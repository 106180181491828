import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Select, Row } from 'antd';
const { Option } = Select;
import { useFetchFormsSAT } from '../../Hooks/FormsPaymentSAT.hook.js';
import { useFetchBranch } from '../../Hooks/Branch.hook';

export const FormsPaymentBranchForm = ({ formRef, onSubmit }) => {
  const [forms, pLoading] = useFetchFormsSAT();
  const [branches, bLoading] = useFetchBranch();

  return (
    <Form form={formRef} layout="vertical" name="PlanForm" onFinish={onSubmit}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label="Descripcion"
            name="description"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Forma de Pago SAT" name="payment_id">
            <Select loading={pLoading}>
              {forms?.data.map(
                oForm =>
                  oForm.status === 1 && (
                    <Select.Option key={oForm.id} value={oForm.id}>
                      {oForm.description} {oForm.code}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Sucursal" name="branch_id">
            <Select loading={bLoading}>
              {branches?.data.map(
                oBranch =>
                  oBranch.status === 1 && (
                    <Select.Option key={oBranch.id} value={oBranch.id}>
                      {oBranch.trade_name}({oBranch.slug})
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            label="Codigo Interno"
            name="code"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

FormsPaymentBranchForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
