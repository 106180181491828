import React from 'react';
import ResetPasswordForm from '../../Components/Forms/ResetPassword.form';

export const ResetPassword = () => {
  return (
    <div className="reset-screen">
      <ResetPasswordForm />
    </div>
  );
};
