import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Select, Row } from 'antd';
const { Option } = Select;

export const RegimeForm = ({ formRef, onSubmit }) => {
  return (
    <Form form={formRef} layout="vertical" name="PlanForm" onFinish={onSubmit}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label="Descripcion"
            name="description"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 8, 8]}>
        <Col span={8}>
          <Form.Item
            label="Codigo"
            name="code"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input type="text" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Tipo" name="type" initialValue={0}>
            <Select>
              <Option value={0}>Fisicas</Option>
              <Option value={1}>Morales</Option>
              <Option value={2}>Ambas</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Estatus" name="status" initialValue={1}>
            <Select>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

RegimeForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
