import React from 'react';
import GenerateInvoiceForm from '../../Components/Forms/GenerateInvoice.form';

export const GenerateInvoice = () => {
  return (
    <div className="generateInvoice-screen">
      <GenerateInvoiceForm />
    </div>
  );
};
