import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  message,
  notification,
  Popconfirm,
  Row,
  Table,
  Tag,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { useFetchBranch } from '../../Hooks/Branch.hook';
import { BranchActions } from './BranchActions';
import { process, SAVE, UPDATE } from '../../Service/Api';
import { useAuth } from '../../Hooks';
import { SearchBar } from '../Atoms/SearchBar';
import { generateQueries } from '../../Utils/query';
import { aSearchElements, oInitState } from './BranchConstants';

export const BranchTable = () => {
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(oInitState);
  const [branches, loading, change, updater] = useFetchBranch();
  const [formRef] = Form.useForm();
  const [{ user }] = useAuth();

  const _handleDeactivate = async sId => {
    const response = await process(
      UPDATE,
      'branches',
      { status: 0 },
      { id: sId }
    );
    if (response?.ok) {
      message.success('Desactivado correctamente');
      updater();
    } else {
      message.error('Error al desactivar');
    }
  };

  const columns = [
    {
      dataIndex: 'trade_name',
      title: 'Razon Social',
    },
    {
      dataIndex: 'invoice_name',
      title: 'Nombre Facturacion',
    },
    {
      dataIndex: 'address',
      title: 'Dirección',
    },
    {
      dataIndex: 'zipcode',
      title: 'Codigo Postal',
    },
    {
      dataIndex: 'rfc',
      title: 'RFC',
    },
    {
      dataIndex: 'slug',
      title: 'Identificador',
    },
    {
      dataIndex: 'route_ftp',
      title: 'Ruta FTP',
      render: route_ftp =>
        route_ftp ? route_ftp : <Tag color="#f50">Null</Tag>,
    },
    {
      dataIndex: 'status',
      title: 'Estatus',
      render: status =>
        status ? (
          <Tag color="#87d068">Activo</Tag>
        ) : (
          <Tag color="#f50">Inactivo</Tag>
        ),
    },
    {
      dataIndex: 'id',
      title: 'Acciones',
      render: (_, row) => {
        return (
          <Row>
            <Col>
              <Button
                onClick={() => {
                  setSelected(row);
                  formRef.setFieldsValue(row);
                  setModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                onConfirm={() => _handleDeactivate(row.id)}
                title="Está seguro de desactivar esta Sucursal?"
              >
                <Button>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        );
      },
    },
  ];

  const _handleReset = () => {
    setSearch(oInitState);
    change();
  };

  const _handleSearch = () => change(generateQueries(search, aSearchElements));

  const _handleSubmit = async values => {
    setModalLoading(true);
    let response;
    let oSend = {
      ...values,
      company_id: user.company_id,
    };

    debugger; // eslint-disable-line no-debugger

    if (selected?.id) {
      response = await process(UPDATE, 'branches', oSend, {
        id: selected.id,
      });
    } else {
      response = await process(SAVE, 'branches', oSend);
    }
    setModalLoading(false);
    if (response?.ok) {
      message.success('Exito');
      formRef.resetFields();
      setSelected({});
      setModal(false);
      updater();
    } else {
      const { data } = response;
      if (data?.code === 400) {
        let sErrors = '';
        if (Array.isArray(data.errors)) {
          for (const oError of data.errors) {
            if (oError.type === 'unique violation') {
              sErrors += `El valor ${oError.path} ya existe en BD\n`;
            }
          }
        } else {
          for (const sKey in data?.errors) {
            sErrors += data.errors[sKey] + '\n';
          }
        }
        message.error(data?.message);
        if (sErrors !== '') {
          notification.error({
            message: sErrors,
            title: 'Errores',
          });
        }
      } else if (data?.code === 409) {
        message.error('Valor duplicado');
      } else {
        message.error('Error en compañia');
      }
    }
    setModalLoading(false);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col className="screen-title">
          <h3>Sucursales</h3>
          <h3>
            <Tag>
              {branches?.total >= 0 ? branches.total : <LoadingOutlined />}
            </Tag>
          </h3>
        </Col>
        <Col>
          <Button onClick={() => setModal(true)} type="primary">
            Crear <BankOutlined />
          </Button>
        </Col>
      </Row>
      <BranchActions
        fn={{
          handler: setModalLoading,
          handlerSelected: setSelected,
          selected: selected,
        }}
        modal={{
          form: formRef,
          handler: setModal,
          loading: modalLoading,
          submit: _handleSubmit,
          visible: modal,
        }}
      />
      <SearchBar
        elements={aSearchElements}
        handleReset={_handleReset}
        handleSearch={_handleSearch}
        {...{ search, setSearch }}
      />
      <Table
        columns={columns}
        dataSource={branches?.data}
        loading={loading}
        pagination={{
          current: branches?.skip ? branches.skip / 10 + 1 : 1,
          onChange: e => change(branches.queries, (e - 1) * 10),
          pageSizeOptions: [10],
          total: branches.total,
        }}
        rowKey={row => row.id}
      />
    </div>
  );
};
