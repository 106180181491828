import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Descriptions } from 'antd';
import { OrderForm } from '../Forms/Orders.form';
import { InvoiceForm } from '../Forms';

export const InvoiceActions = ({
  fn = {
    handler: () => ({}),
    handlerSelected: () => ({}),
    selected: {},
  },
  modal = {
    form: {},
    handler: () => ({}),
    loading: false,
    submit: () => ({}),
    visible: false,
  },
  modalSecondModule = {
    form: {},
    handler: () => ({}),
    loading: false,
    submit: () => ({}),
    visible: false,
  },
}) => {
  return modalSecondModule?.visible ? (
    <Modal
      cancelText="Cancelar"
      centered
      destroyOnClose
      onCancel={() => {
        fn.handlerSelected({});
        modalSecondModule.handler(false);
        modalSecondModule.form.resetFields();
      }}
      onOk={() => modalSecondModule.form.submit()}
      okText={fn.selected?.id ? 'Actualizar' : 'Crear'}
      okButtonProps={{
        loading: modalSecondModule.loading,
      }}
      title={fn.selected?.id ? 'Actualizar Orden' : 'Crear Orden'}
      visible={modalSecondModule.visible}
      width={800}
    >
      <OrderForm
        formRef={modalSecondModule.form}
        isUpdating={!!fn.selected?._id}
        onSubmit={modalSecondModule.submit}
      />
    </Modal>
  ) : (
    <Modal
      cancelText="Cancelar"
      centered
      destroyOnClose
      onCancel={() => {
        fn.handlerSelected({});
        modal.handler(false);
        modal.form.resetFields();
      }}
      onOk={() => modal.form.submit()}
      okText={fn.selected?.id ? 'Timbrar ' : 'Timbrar'}
      okButtonProps={{
        loading: modal.loading,
      }}
      title={`Crear Factura de Orden ${fn.selected.serie}-${fn.selected.folio}`}
      visible={modal.visible}
    >
      <Descriptions title="Factura">
        <Descriptions.Item label="Subtotal">
          {fn.selected.total_bruto}
        </Descriptions.Item>
        <Descriptions.Item label="IVA">
          {(fn.selected.total_bruto * 0.16).toFixed(2)}
        </Descriptions.Item>
        <Descriptions.Item label="Total">
          {(fn.selected.total_bruto * 1.16).toFixed(2)}
        </Descriptions.Item>
      </Descriptions>

      <InvoiceForm
        formRef={modal.form}
        onSubmit={modal.submit}
        oOrder={fn.selected}
      />
    </Modal>
  );
};

InvoiceActions.propTypes = {
  fn: PropTypes.any.isRequired,
  modal: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
  }),
  modalSecondModule: PropTypes.shape({
    form: PropTypes.object,
    handler: PropTypes.func,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    visible: PropTypes.bool,
  }),
};
