import React from 'react';
import { BranchesPaymentTable } from '../../Components/BranchesPayments/BranchesPaymentTable';

export const BranchesPaymentScreen = () => {
  return (
    <div className="branches-screen">
      <BranchesPaymentTable />
    </div>
  );
};
