import React from 'react';
import { Select } from 'antd';

export const aSearchElements = [
  {
    label: 'Nombre/Razón Social',
    name: 'invoice_name',
    placeholder: 'Nombre/Razón Social',
    type: 'input',
  },
  {
    label: 'RFC',
    name: 'rfc',
    placeholder: 'Busqueda por RFC',
    type: 'input',
  },
  {
    label: 'Estatus',
    name: 'status',
    placeholder: 'Todos',
    type: 'select',
    values: () => (
      <>
        <Select.Option value={1}>Activo</Select.Option>
        <Select.Option value={0}>Inactivo</Select.Option>
      </>
    ),
  },
];

export const oInitState = {
  invoice_name: '',
  rfc: '',
  status: undefined,
};
