import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import PropTypes from 'prop-types';

export const InvoiceCancelForm = ({ formRef, onSubmit }) => {
  const cancelMotives = [
    {
      id: '02',
      label: 'Comprobantes emitidos con errores sin relación',
    },
    {
      id: '03',
      label: 'No se llevó a cabo la operación',
    },
  ];

  return (
    <Form
      form={formRef}
      layout="vertical"
      name="rejectComment"
      onFinish={onSubmit}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label="Folio"
            name="folio"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Serie"
            name="serie"
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item label="Motivo de cancelación" name="motive">
            <Select>
              {cancelMotives.map(motive => (
                <Select.Option key={motive.id} value={motive.id}>
                  {motive.id} - {motive.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

InvoiceCancelForm.propTypes = {
  formRef: PropTypes.object,
  onSubmit: PropTypes.func,
};
